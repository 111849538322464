import React from "react"

import {withSEO} from "src/hoc/SEO"

const NotFoundPage = () => (
  <React.Fragment>
    <h1>Something Went Wrong</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </React.Fragment>
);

export default withSEO()(NotFoundPage);
